import React, { useEffect, useMemo, useState } from "react"
import PageRoot from "../components/PageRoot"
import { Col, Empty, Row } from "antd"
import { graphql } from "gatsby"
import AtsCarousel from "../components/AtsCarousel"
import {
  getCategoryDataSource,
  getHomePageData,
  getLatestItems,
  getMostViewedItemList,
  renderCarouselItem,
  translatePath,
} from "../utils"
import HeroHeader from "../components/HeroHeader"
import useFavoriteItems from "../hooks/useFavoriteItems"
import { useAuth0 } from "@auth0/auth0-react"
import { useTranslation } from "react-i18next"
import { ON_DEMAND } from "../enums/PricePolicies"
import { MASTER } from "../enums/CertificationTypes"
import { PATH } from "../enums/ItemTypes"

const HomePage = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { mostViewedItems } = pageContext

  const {
    videos,
    courses,
    categories,
    authors,
    channels,
    featureCategories,
    bundles,
  } = data.ats

  const onDemandCourses = courses.filter(
    course => course.price_policy === ON_DEMAND
  )
  const otherCourses = courses.filter(
    course => course.price_policy !== ON_DEMAND
  )

  const onDemandMasters = bundles.filter(
    bundle => bundle.course_type === MASTER
  )

  const onDemandPrograms = bundles.filter(bundle => bundle.course_type === PATH)

  const generalBundles = bundles.filter(
    bundle => bundle.course_type !== PATH && bundle.course_type !== MASTER
  )

  const allItemList = [...videos, ...courses]

  const [featuredItemList, setFeaturedItemList] = useState([])
  const [latestList, setLatestList] = useState([])
  const [suggestedItemList, setSuggestedItemList] = useState([])
  const [mostViewedItemList, setMostViewedItemList] = useState([])
  const favoriteItemList = useFavoriteItems(allItemList)
  const { isAuthenticated } = useAuth0()
  const [debugMode, setDebugMode] = useState(false)

  const _getMostViewedItemList = async () => {
    try {
      const itemList = mostViewedItems

      if (!itemList) {
        setMostViewedItemList(null)
        return
      }

      const result = []
      itemList.map(item => {
        const video = allItemList.find(({ id }) => id === item.id)
        if (video) {
          result.push(video)
        }
      })

      setMostViewedItemList(result)
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    const { videos, courses, global_settings } = data.ats

    const homePageSettings = JSON.parse(
      global_settings.find(({ key }) => key === "HOME_PAGE_LAYOUT")?.value ??
        "{}"
    )

    const { featuredItemList, suggestedItemList } = getHomePageData(
      homePageSettings,
      videos,
      courses
    )

    _getMostViewedItemList()

    setFeaturedItemList(featuredItemList)
    setLatestList(getLatestItems(videos, courses))
    setSuggestedItemList(suggestedItemList)
  }, [])

  const heroSlider = useMemo(() => {
    return <HeroHeader dataSource={featuredItemList} />
  }, [featuredItemList])

  const latestItemRow = useMemo(() => {
    return (
      <AtsCarousel
        title={t("label:latest")}
        dataSource={latestList}
        renderItem={renderCarouselItem}
      />
    )
  }, [latestList])

  const favoritesRow = useMemo(() => {
    if (Array.isArray(favoriteItemList) && favoriteItemList.length) {
      return (
        <AtsCarousel
          title={t("label:myList")}
          dataSource={favoriteItemList}
          loading={favoriteItemList === null}
          renderItem={renderCarouselItem}
          emptyItem={
            <Empty description={"Non hai ancora aggiunto alcun video"} />
          }
        />
      )
    } else return null
  }, [favoriteItemList])

  const mostViewedRow = useMemo(() => {
    return (
      <AtsCarousel
        title={t("label:mostFollowed")}
        dataSource={mostViewedItemList}
        loading={mostViewedItemList === null}
        renderItem={(item, index) => {
          return renderCarouselItem(item, index, true)
        }}
      />
    )
  }, [mostViewedItemList])

  const channelsRow = useMemo(() => {
    return (
      <AtsCarousel
        colSettings={{ xs: 12, sm: 12, md: 8, lg: 6, xl: 5, xxl: 4 }}
        url={translatePath("/channel/all")}
        title={t("label:channels")}
        dataSource={channels}
        renderItem={renderCarouselItem}
      />
    )
  }, [channels])

  const suggestionsRow = useMemo(() => {
    return (
      <AtsCarousel
        title={t("label:suggested")}
        dataSource={suggestedItemList}
        renderItem={renderCarouselItem}
      />
    )
  }, [suggestedItemList])

  const categoriesRow = useMemo(() => {
    return (
      <AtsCarousel
        colSettings={{ xs: 14, sm: 14, md: 8, lg: 6, xl: 5, xxl: 4 }}
        url={translatePath("/category/all")}
        title={t("label:categories")}
        dataSource={categories}
        renderItem={renderCarouselItem}
      />
    )
  }, [categories])

  const coursesRow = useMemo(() => {
    return (
      <AtsCarousel
        title={t("label:allSeries")}
        dataSource={otherCourses}
        renderItem={renderCarouselItem}
      />
    )
  }, [otherCourses])

  const authorsRow = useMemo(() => {
    return (
      <AtsCarousel
        colSettings={{ xs: 10, sm: 10, md: 8, lg: 6, xl: 4, xxl: 3 }}
        title={t("label:authors")}
        url={translatePath("/author/all")}
        dataSource={authors}
        renderItem={renderCarouselItem}
      />
    )
  }, [authors])

  const mastersRow = useMemo(() => {
    return (
      <AtsCarousel
        colSettings={{
          xs: 12,
          md: 8,
        }}
        title={t("label:onDemandMasters")}
        url={translatePath("/on-demand/masters")}
        dataSource={onDemandMasters}
        renderItem={renderCarouselItem}
      />
    )
  }, [bundles])

  const programsRow = useMemo(() => {
    return (
      <AtsCarousel
        colSettings={{
          xs: 12,
          md: 8,
          lg: 8,
        }}
        title={t("label:onDemandPrograms")}
        url={translatePath("/on-demand/programs")}
        dataSource={onDemandPrograms}
        renderItem={renderCarouselItem}
      />
    )
  }, [bundles])

  const bundlesRow = useMemo(() => {
    return (
      <AtsCarousel
        colSettings={{
          xs: 12,
          md: 8,
        }}
        title={t("label:bundles")}
        url={translatePath("/me/library")}
        dataSource={generalBundles}
        renderItem={renderCarouselItem}
      />
    )
  }, [bundles])

  const onDemandCoursesRow = useMemo(() => {
    return (
      <AtsCarousel
        isVertical={true}
        title={t("label:onDemandCourses")}
        url={translatePath("/on-demand")}
        dataSource={onDemandCourses}
        renderItem={renderCarouselItem}
        colSettings={{
          xs: 12,
          md: 8,
          lg: 4,
        }}
      />
    )
  }, [onDemandCourses])

  const categoriesWithContentRow = useMemo(() => {
    return featureCategories.map(category => (
      <Row key={category.id}>
        <AtsCarousel
          title={category.name}
          dataSource={getCategoryDataSource(category)}
          renderItem={renderCarouselItem}
        />
      </Row>
    ))
  }, [featureCategories])

  useEffect(() => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const queryParam = params.get("debug")
    setDebugMode(Boolean(queryParam))
  }, [])

  return (
    <PageRoot>
      {heroSlider}
      <Row>
        <Col span={24}>{latestItemRow}</Col>
        {isAuthenticated && <Col span={24}>{favoritesRow}</Col>}
        <Col span={24}>{mostViewedRow}</Col>
        <Col span={24}>{channelsRow}</Col>
        <Col span={24}>{suggestionsRow}</Col>
        <Col span={24}>{categoriesRow}</Col>
        <Col span={24}>{coursesRow}</Col>
        <Col span={24}>{authorsRow}</Col>
        {Boolean(onDemandMasters.length) && (
          <Col span={24}>
            <div className={"on-demand-masters-grid"}>{mastersRow}</div>
          </Col>
        )}
        {Boolean(onDemandPrograms.length) && (
          <Col span={24}>
            <div className={"on-demand-programs-grid"}>{programsRow}</div>
          </Col>
        )}
        <Col span={24}>{onDemandCoursesRow}</Col>
        {Boolean(generalBundles.length) && (
          <Col span={24}>
            <div className={"on-demand-masters-grid"}>{bundlesRow}</div>
          </Col>
        )}
        <Col span={24}>{categoriesWithContentRow}</Col>
      </Row>
    </PageRoot>
  )
}

export default HomePage

export const query = graphql`
  query homePageQuery {
    ats {
      videos(
        order_by: { created_at: desc }
        where: {
          _and: [
            {
              _or: [
                { state: { _eq: PUBLISHED } }
                { state: { _eq: COMING_SOON } }
              ]
            }
            {
              _or: [
                {
                  course_rel: { course: { price_policy: { _neq: ON_DEMAND } } }
                }
              ]
            }
          ]
        }
      ) {
        ...PreviewCardVideo
        header_image {
          ...SvgImage
        }
        wallpaper {
          ...Wallpaper
        }
        author_rels {
          author {
            id
            firstname
            lastname
            title
          }
        }
        course_rel {
          course {
            ...PreviewCardCourse
          }
        }
      }
      courses(
        order_by: { created_at: desc }
        where: {
          _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
        }
      ) {
        ...PreviewCardCourse
        header_image {
          ...SvgImage
        }
        wallpaper {
          ...Wallpaper
        }
      }
      categories(where: { _not: { parent: {} } }, order_by: { name: asc }) {
        ...PreviewCardCategory
      }
      channels {
        ...PreviewCardChannel
      }
      global_settings {
        key
        value
      }
      authors {
        ...PreviewCardAuthor
      }
      featureCategories: categories(
        where: {
          id: {
            _in: [
              "56620c18-5a7c-4d15-b631-627177e22ed8"
              "e5fabc0f-6fcf-4265-ba4e-5fcce8695668"
              "e5fabc0f-6fcf-4265-ba4e-5fcce8695668"
              "274c0ecd-97c7-47dd-bc9e-6c92204d32a7"
              "1e7dbf9b-bcdc-4963-a159-5940d1a7f46b"
            ]
          }
        }
      ) {
        ...CategoryWithAllContent
      }
      bundles(
        order_by: { created_at: desc }
        where: { state: { _eq: "PUBLISHED" } }
      ) {
        ...PreviewCardBundle
        course_type
        description
        seo {
          slug
        }
        header_image {
          ...SvgImage
        }
        wallpaper {
          ...Wallpaper
        }
      }
    }
  }
`
