import React from "react"
import { useGlobalValue } from "./useGlobalValue"

const useFavoriteItems = allData => {
  const [{ userFavorites }] = useGlobalValue()

  const result = []
  if (userFavorites) {
    userFavorites.map(({ item_id }) => {
      const item = allData.find(({ id }) => id === item_id)
      if (item) {
        result.push(item)
      }
    })
  } else {
    return null
  }

  return result
}

export default useFavoriteItems
